<template>
  <div class="row">
    <div class="col-12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalPDF ref="visorPDF"></ModalPDF>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <transition name="slide">
        <div class="card border-warning">
          <div class="card-header">
            <strong>Registrar Contrato</strong>
            <div class="card-header-actions">
              <button @click="$refs.modalVideo.mostrarVideo('video-alumno-contrato.mp4')"
                      class="btn btn-outline-info" title="Ver video de ayuda">
                <font-awesome-icon icon="fas fa-question-circle"/>
                Ayuda
              </button>
            </div>
          </div>
          <CForm id="frm-hojaruta">
            <div class="card-body p-1">
              <template slot="header">
                Codigo de estudiante: {{ $route.params.id }}
              </template>
              <div class="table-responsive">
                <table class="table ">
                  <tr>
                    <td>Carrera</td>
                    <td><strong>{{ carrera }}</strong></td>
                    <td>Nombres, apellidos</td>
                    <td><strong>
                      {{ inscripcion.nombres }}, {{ inscripcion.apellido1 }} {{ inscripcion.apellido2 }}</strong></td>
                  </tr>
                  <tr>
                    <td>Gestión:</td>
                    <td><strong>{{ inscripcion.gestion }}</strong></td>
                    <td>C&oacute;digo del Alumno:</td>
                    <td><strong>{{ inscripcion.codigo_alumno }}</strong></td>
                  </tr>
                  <tr>
                    <td>Nacionalidad:</td>
                    <td><strong>{{ nacionalidad }}</strong></td>
                    <td>C.I./Pasaporte/DNI N°:</td>
                    <td><strong>{{ inscripcion.documento_identificacion }}</strong></td>
                  </tr>
                </table>
              </div>
              <hr/>
              <div class="row mb-3 ps-2 pl-2">
                <div class="col-2">Colegiatura (Bs.)</div>
                <div class="col-2">
                  <input v-model="contrato.colegiatura_base" class="form-control text-center" min="1"
                         type="number" value="700"
                         @change="calcularcolegiatura()">
                </div>
                <div class="col-3"></div>
              </div>
              <div class="row mb-3 ps-2 pl-2">
                <div class="col-2">Descuento (%)</div>
                <div class="col-2">
                  <input v-model="contrato.porcentaje_descuento_beca" class="form-control text-center"
                         max="100" min="0" type="number"
                         value="33.4">
                </div>
                <div class="col-5">
                  <v-select :options="becas" :reduce="country => country.value" label="label"
                            v-model="contrato.descuento_beca_id" @input="calcularcolegiaturaBeca()"/>
                </div>
              </div>
              <div class="row ps-2 pl-2">
                <div class="col-2">Nueva colegiatura (Bs.)</div>
                <div class="col-2">
                  <input v-model="contrato.colegiatura_gestion" class="form-control text-center" min="1" type="number">
                </div>
              </div>
              <hr/>
              <h6 class="text-center text-warning">Ejemplo de distribución de costo por materia</h6>
              <div class="row px-3">
                <table class="table table-bordered table-sm table-striped">
                  <tr>
                    <th class="text-center" style="width: 5%">Nro</th>
                    <th class="text-center" style="width: 10%">Código</th>
                    <th class="text-center" style="width: 42%">Materia</th>
                    <th class="text-center" style="width: 8%">Semestre</th>
                    <th class="text-center" style="width: 15%">Costo (Bs)</th>
                    <th class="text-center d-none" style="width: 20%">Obsservación</th>
                  </tr>
                  <tr v-for="(materia,index) in materias">
                    <td class="text-center">{{ (index+1) }}</td>
                    <td class="text-center">{{ materia.sigla }}</td>
                    <td class="text-center">{{ materia.materia }}</td>
                    <td class="text-center">{{ materia.semestre }}</td>
                    <td class="text-center">
                      <input type="number" step="0,1" min="0" class="form-control form-control-sm text-center"
                      v-model="materiasMonto[materia.id]" @change="sumarCostoMaterias"/>
                    </td>
                    <td class="text-center d-none">
                      <input type="number" step="0,1" min="0" class="form-control form-control-sm text-center"
                      v-model="materiasObservacion[materia.id]"/>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-right" colspan="4"><strong>TOTAL</strong></td>
                    <td class="text-center">
                      <input type="number" step="0,1" min="0" class="form-control form-control-sm text-center"
                      v-model="materiasTotal"/>
                    </td>
                    <td class="d-none"></td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="card-footer">
              <CButton class="mr-2" color="success" @click="update()" v-text="btnGrabar">Grabar</CButton>
              <CButton class="mr-2" color="outline-danger" @click="reportePdf()">
                <font-awesome-icon icon="fa-regular fa-file-pdf" size="xl"/>
                Contrato PDF
              </CButton>

              <CButton class="mr-2" color="outline-primary" @click="reporteWord()">
                <font-awesome-icon icon="fa-regular fa-file-word" size="xl"/>
                Contrato Word
              </CButton>
              <CButton color="primary" @click="goBack">Regresar a la pantalla anterior</CButton>
            </div>
          </CForm>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Table from "@/views/base/Table";
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalPDF from "@/views/notifications/ModalPDF";
import ModalVideo from "@/views/notifications/ModalVideo";

var modalPdf;

export default {
  name: 'Documentos',
  components: {ModalVideo, ModalPDF, Alerts, Toast, Table},
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      becas: [],
      darkModal: false,
      inscripcion: {},
      carreras: [],
      materias: [],
      materiasMonto: [],
      materiasObservacion: [],
      materiasTotal:0,
      carrera: '',
      nacionalidades: [],
      nacionalidad: '',
      btnGrabar: '',
      colegiatura_base: 0,
      contrato: {
        id: 0,
        inscripcion_id: 0,
        descuento_beca_id: 0,
        colegiatura_base: 0,
        porcentaje_descuento_beca: 0,
        colegiatura_gestion: 0,
      }
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    reportePdf() {
      modalPdf.$refs.alert.show("Descargando documento");
      axios.get(
          modalPdf.$apiAdress + '/api/inscripcion/' + modalPdf.$route.params.id + '/' + modalPdf.$route.params.gestion + '/contratopdf?token=' + localStorage.getItem("api_token"),
          {responseType: 'blob'}
      )
          .then((response) => {
            modalPdf.$refs.alert.hide();
            modalPdf.$refs.visorPDF.mostrarDocumento(response.data);
          })
          .catch(function (error) {
            modalPdf.$refs.alert.hide();
            //modalPdf.$refs.mensajeToast.makeToast(error, modalPdf);
            modalPdf.$refs.mensajeToast.makeToast('', 'Error al generar el contrato, primero debe grabar los datos antes de generar el archivo PDF', 'success');
          });
    },
    reporteWord() {
      modalPdf.$refs.alert.show("Descargando documento");
      axios.get(
          modalPdf.$apiAdress + '/api/inscripcion/' + modalPdf.$route.params.id + '/' + modalPdf.$route.params.gestion + '/contratoword?token=' + localStorage.getItem("api_token"),
          {responseType: 'blob'}
      )
          .then((response) => {
            modalPdf.$refs.alert.hide();
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'CONTRATO ' + modalPdf.inscripcion.gestion + ' - ' + modalPdf.inscripcion.nombres + ' ' + modalPdf.inscripcion.apellido1 + ' ' + modalPdf.inscripcion.apellido2 + '.docx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
          })
          .catch(function (error) {
            console.log(error)
            modalPdf.$refs.alert.hide();
            //modalPdf.$refs.mensajeToast.makeToast(error, modalPdf);
            modalPdf.$refs.mensajeToast.makeToast('', 'Error al generar el contrato, primero debe grabar los datos antes de generar el archivo PDF', 'success');
          });
    },
    calcularcolegiatura() {
      try {
        this.contrato.porcentaje_descuento_beca = this.becas.filter(function (chain) {
          return chain.value === modalPdf.contrato.descuento_beca_id;
        })[0].porcentaje;
        let colegiatura = this.becas.filter(function (chain) {
          return chain.value === modalPdf.contrato.descuento_beca_id;
        })[0].colegiatura_convenio;
        if(this.contrato.colegiatura_base == 0) {
          if (colegiatura == 0) {
            this.contrato.colegiatura_base = colegiatura
          } /*else {
          this.contrato.colegiatura_base = this.colegiatura_base
        }*/
        }
      } catch (e) {
        this.contrato.porcentaje_descuento_beca = 0
      }
      this.contrato.colegiatura_gestion = Math.round(this.contrato.colegiatura_base - (this.contrato.colegiatura_base * (this.contrato.porcentaje_descuento_beca / 100) * 10) * 1.0 / 10);
      this.distribuirCostoMaterias()
    },
    calcularcolegiaturaBeca() {
      try {
        this.contrato.porcentaje_descuento_beca = this.becas.filter(function (chain) {
          return chain.value === modalPdf.contrato.descuento_beca_id;
        })[0].porcentaje;
        let colegiatura = this.becas.filter(function (chain) {
          return chain.value === modalPdf.contrato.descuento_beca_id;
        })[0].colegiatura_convenio;
        if (colegiatura > 0) {
          this.contrato.colegiatura_base = colegiatura
        } else {
          this.contrato.colegiatura_base = this.colegiatura_base
        }
      } catch (e) {
        this.contrato.porcentaje_descuento_beca = 0
      }
      this.contrato.colegiatura_gestion = Math.round(this.contrato.colegiatura_base - (this.contrato.colegiatura_base * (this.contrato.porcentaje_descuento_beca / 100) * 10) * 1.0 / 10);
      this.distribuirCostoMaterias()
    },
    update() {
      modalPdf.$refs.alert.show("Grabando datos");
      modalPdf.contrato.inscripcion_id = modalPdf.$route.params.id;
      axios.post(this.$apiAdress + '/api/inscripcion/contratograbar?token=' + localStorage.getItem("api_token"),
          modalPdf.contrato)
          .then(function (response) {
            modalPdf.$refs.mensajeToast.makeToast('Info', 'Contrato registrado/actualizado.', 'success');
            modalPdf.$refs.alert.hide();
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    sumarCostoMaterias(){
      let total=0;
      modalPdf.materiasMonto.forEach(function (data, index) {
        total+=parseFloat(data)
      });
      total = Math.round(total*100)
      total = total / 100
      modalPdf.materiasTotal=total
    },
    distribuirCostoMaterias(){
      let total=0;
      let costo=0;
      if(modalPdf.materias.length>0) {
        costo = parseFloat(modalPdf.contrato.colegiatura_gestion) / modalPdf.materias.length
        costo = Math.floor(costo*100)
        costo = costo/100
      }
      modalPdf.materias.forEach(function (data, index) {
        if((index+1)<modalPdf.materias.length) {
          modalPdf.materiasMonto[data.id] = costo
          total += parseFloat(costo)
        }else{
          modalPdf.materiasMonto[data.id] = parseFloat(modalPdf.contrato.colegiatura_gestion)-total
          modalPdf.materiasMonto[data.id] = Math.round(modalPdf.materiasMonto[data.id]*100)
          modalPdf.materiasMonto[data.id] = modalPdf.materiasMonto[data.id] / 100
        }
      });
      this.sumarCostoMaterias()
    }
  },
  mounted: function () {
    modalPdf = this;
    axios.get(modalPdf.$apiAdress + '/api/inscripcion/0/' + modalPdf.$route.params.codigo_alumno + '/edit?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          modalPdf.inscripcion = response.data.inscripcion;

          axios.get(modalPdf.$apiAdress + '/api/inscripcion/' + localStorage.sedeSeleccionada + '/create?token=' + localStorage.getItem("api_token"))
              .then(function (response) {
                let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
                modalPdf.carreras = response.data.carreras;
                modalPdf.nacionalidades = valorPorDefecto.concat(response.data.nacionalidades);
                for (let fac in modalPdf.carreras) {
                  for (let carr in modalPdf.carreras[fac]) {
                    if (modalPdf.carreras[fac][carr].value == modalPdf.inscripcion.carrera_id) {
                      modalPdf.carrera = modalPdf.carreras[fac][carr].label;
                    }
                  }
                }
                for (let fac in modalPdf.nacionalidades) {
                  if (modalPdf.nacionalidades[fac].value == modalPdf.inscripcion.nacionalidad_id) {
                    modalPdf.nacionalidad = modalPdf.nacionalidades[fac].label;
                  }
                }
              })
              .catch(function (error) {
                modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
              });
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
    axios.get(this.$apiAdress + '/api/inscripcion/' + modalPdf.$route.params.id + '/' + localStorage.sedeSeleccionada + '/contratodatos?token=' + localStorage.getItem("api_token"))
        .then(function (response) {
          modalPdf.becas = response.data.becas;
          modalPdf.contrato = response.data.contrato;
          modalPdf.materias = response.data.materias;
          modalPdf.colegiatura_base = modalPdf.contrato.colegiatura_base;
          if (modalPdf.contrato.colegiatura_gestion == 0) {
            modalPdf.calcularcolegiatura();
          }
          if (modalPdf.contrato.id > 0) {
            modalPdf.btnGrabar = 'Modificar datos del contrato';
          } else {
            modalPdf.btnGrabar = 'Grabar contrato';
          }
        })
        .catch(function (error) {
          modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
        });
  }
}
</script>