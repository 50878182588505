<template>
  <div style="bottom: 40px !important; right: 10px !important; position: fixed!important;z-index:10000">
    <div class="toast-container">
      <div v-for="(toast, index) in toasts" :class="'toast show bg-'+toast.backgroud+' bg-gradient text-white'"
           :id="'toast'+index"
           role="alert" aria-live="assertive" aria-atomic="true">
        {{ dismissToast(index, toast.isHide) }}
        <div class="row">
          <div class="col-9">
            <div class="toast-body" :alt="toast.title">
              {{ toast.content }}.
            </div>
          </div>
          <div class="col-1 pl-0 pr-0 pt-2 text-right">
            <span class="ml-auto mt-2 mr-3" :id="'count'+index">
              ({{ toastsDismissTime }})</span>
          </div>
          <div class="col-2 pt-1">
            <button type="button" :class="'btn btn-'+toast.backgroud+' border-0 text-white pt-2'"
                    style="webkit-box-shadow:none !important;box-shadow:none !important;"
                    @click="removeToast(index)"><b>X</b></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Toast',
  data() {
    return {
      toasts: [],
      toastsDismiss: [],
      toastsInterval: [],
      toastsDismissTime: 15
    }
  },
  methods: {
    makeToast(title, content, backgroud='danger') {
      this.toasts.push({
        title: title,
        content: content,
        backgroud: backgroud,
        isHide: false
      })
    },
    dismissToast(index, isHide) {
      if (!isHide) {
        let self = this;
        if (self.toastsDismiss['toast' + index] === undefined) {
          self.toastsDismiss['toast' + index] = self.toastsDismissTime;
          self.toastsInterval['interval' + index] = setInterval(function () {
            self.toastsDismiss['toast' + index]--;
            if (document.getElementById('count' + index) !== null) {
              if (!isNaN(self.toastsDismiss['toast' + index]) && self.toastsDismiss['toast' + index] > -1) {
                document.getElementById('count' + index).innerHTML = "(" + self.toastsDismiss['toast' + index] + ")";
              }
              if (self.toastsDismiss['toast' + index] == 0) {
                self.removeToast(index)
              }
            }
          }, 1000);
        }
      }
    },
    removeToast(index) {
      let self = this;
      document.getElementById('toast' + index).remove();
      delete self.toastsDismiss['toast' + index];
      delete self.toastsInterval['interval' + index];
      self.toasts[index].isHide = true;
      clearInterval(self.toastsInterval['interval' + index]);

    },
    mostrarError: function (error, modalPdf) {
      if (error.response) {
        // console.log(error.response);
        // console.log(error.response.data);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        if (error.response.status == 401) {
          this.$router.push({path: '/login'});
        } else if (error.response.data.message.includes('Duplicate entry')) {
          modalPdf.$refs.mensajeToast.makeToast('', 'No se pueden registrar 2 veces los mismos datos', 'danger');
        } else if (error.response.data.message == 'The given data was invalid.') {
          for (let key in error.response.data.errors) {
            if (error.response.data.errors.hasOwnProperty(key)) {
              modalPdf.$refs.mensajeToast.makeToast('Error', error.response.data.errors[key][0], 'danger');
            }
          }
        } else if (error.response.data.message != '') {
          modalPdf.$refs.mensajeToast.makeToast('Error', error.response.data.message, 'danger');
        } else if (error.response.data.status == 'error') {
          modalPdf.$refs.mensajeToast.makeToast('Error', error.response.data.message, 'danger')
        } else {
          modalPdf.$refs.mensajeToast.makeToast('Error', error.response.statusText, 'success');
        }
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      modalPdf.$refs.alert.hide();
    },
  }
}
</script>