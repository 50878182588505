<template>
  <CModal
      :centered="true"
      :closeOnBackdrop="false"
      :fade="true"
      :no-close-on-backdrop="true"
      :show.sync="darkModal"
      size="xl"
  >
    <template #body-wrapper v-if="type=='pdf'">
      <div class="modal-body p-0 m-0">
        <embed ref="reporteFrame" height="550" src="" type="application/pdf"
               width="100%">
      </div>
    </template>
    <template #header>
      <h6 class="modal-title">
        <a ref="reporteDescargarBtn" download="download" href="">Descargar el documento</a>
      </h6>
      <CButtonClose @click="cerrarDocumento()"/>
    </template>
    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>

export default {
  name: 'ModalPDF',
  data() {
    return {
      darkModal: false,
      type: 'pdf',
      method: {type: Function},
    }
  },
  methods: {
    mostrarDocumento(data, type = 'pdf', funcion=null) {
      this.type = type;
      if (type == 'pdf') {
        this.$refs.reporteFrame.setAttribute('src', URL.createObjectURL(data)+"#view=FitH");
      }
      this.$refs.reporteDescargarBtn.setAttribute('href', URL.createObjectURL(data));
      if ( funcion != null ) {
        this.method = funcion
      }
      this.darkModal = true;
    },
    cerrarDocumento(){
      if ( this.method != null ) {
        if (typeof this.method === 'function') {
          this.method()
        }
      }
      this.darkModal = false
    }
  }
}
</script>