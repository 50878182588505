<template>
  <CModal
      :centered="true"
      :closeOnBackdrop="false"
      :fade="true"
      :no-close-on-backdrop="true"
      :show.sync="darkModal"
      size="xl"
  >
    <template #body-wrapper>
      <div class="modal-body p-0 m-0">
        <iframe ref="reporteFrame" height="550" src="" width="100%"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="Ayuda"/>
      </div>
    </template>
    <template #header>
      <CButtonClose @click="cerrarDocumento()"/>
    </template>
    <template #footer-wrapper><span></span></template>
  </CModal>
</template>

<script>

export default {
  name: 'ModalVideo',
  data() {
    return {
      darkModal: false,
      type: 'pdf',
      method: {type: Function},
    }
  },
  methods: {
    mostrarVideo(data, funcion=null) {
      this.$refs.reporteFrame.setAttribute('src', '/video/'+data);
      if ( funcion != null ) {
        this.method = funcion
      }
      this.darkModal = true;
    },
    cerrarDocumento(){
      if ( this.method != null ) {
        if (typeof this.method === 'function') {
          this.method()
        }
      }
      this.darkModal = false
    }
  }
}
</script>