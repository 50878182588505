<template>
  <CModal
      :show.sync="modal"
      :no-close-on-backdrop="true"
      :centered="true"
      :closeOnBackdrop="false"
      title="Modal title 2"
      color="dark"
  >
    <div class="d-flex align-items-center">
      <strong id="modalLoaderTexto">{{ body }}</strong>
      <div class="spinner-border ml-auto" role="status" aria-hidden="true"></div>
    </div>
    <template #header-wrapper>&nbsp;
    </template>
    <template #footer-wrapper>
      <div class="modal-footer" style="justify-content:flex-start!important;">
        <small>
          <div class="spinner-grow spinner-grow-sm text-secondary mr-3" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <i>Por favor espere a que carguen los datos</i>
        </small>
      </div>
    </template>
  </CModal>
</template>

<script>

export default {
  name: 'Alerts',
  //props: ["title", "btncancelar", "btnaceptar"],
  data() {
    return {
      body: '',
      modal: false
    }
  },
  methods: {
    show(contenido) {
      this.body = contenido;
      this.modal = true;
    },
    hide() {
      this.modal = false;
    },
    hasRole(roleUser) {
      let roles = localStorage.getItem("roles");
      if (roles != null) {
        roles = roles.split(',')
        if (roles != null && roles.indexOf(roleUser) >= 0) {
          return true
        }
      }
      return false;
    },
    showHideColumn(role,gridSystem) {
      try {
        if (!this.hasRole(role)) {
          gridSystem.hidecolumn(role);
        }
      } catch (e) {
      }
    },
  }
}
</script>
